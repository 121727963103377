.all_post_area{
    width: 95%;
    max-width: 2000px;
    margin: 4% auto 5%;
    display: grid;    
    grid-template-columns: repeat(3, minmax(200px, 1fr));
}

.post_outline{
    display: flex;
    flex-direction: column;
    margin: 10px;
    color: var(--text-color);
    border: 1px solid var(--slider-color);
    border-radius: 10px;
    text-decoration: none;
    box-shadow: -4px 4px var(--slider-color);
}

.post_outline img{
    grid-area: image;
    height: 300px;
    width: 98%;
    border-radius: 10px;
    border: 1px solid var(--slider-color);
    margin: 1% auto 2%;
}

.post_outline .Heading{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: heading;
}

.post_outline .Heading .ArticleId{
    grid-area: title;
    font-size: 1.5em;
    font-weight: 800;
    margin: 2% 5px 1% 5%;
    padding-right: 10px;
    border-right: 1px solid var(--slider-color);
    /* color:  var(--post-content-font); */
    display: flex;
    align-items: flex-start;
    color: var(--article-id-color);
}

.post_outline .Heading .title{
    grid-area: title;
    font-size: 1.5em;
    font-weight: 800;
    margin: 2% 5%;
    word-spacing: 2px;
    color: var(--post-heading-font);
}

.post_outline .content{
    grid-area: post-content;
    font-size: 1.1em;
    margin: 2% 5%;
    word-spacing: 4px;
    color: var(--post-content-font);
}

.post_outline .category{
    grid-area: category;
    width: fit-content;
    height: fit-content;
    font-size: 1em;
    margin: 2% 5%;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #7feb7f;
    justify-items: center;
}

.post_outline .date-time{
    grid-area: date-time;
    margin: 2% 5% 3%;
    color: var(--post-content-font);
}

.post_outline .link{
    grid-area: link;
    width: fit-content;
    padding: 3px 8px;
    margin: 1% 5% 5% 5%;
    border-radius: 5px;
    color: var(--text-color);
    background-color: #68c1fc;
    box-shadow: -2px 2px #017fd2;
    text-decoration: none;
}


/* Loader Begins */

.loader {
    width: 200px;
    height: 140px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #979794;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    perspective: 1000px;
}

.loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
        105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    transform: rotateY(0deg );
    transform-origin: left center;
animation: paging 1s linear infinite;
  }


@keyframes paging {
    to {
        transform: rotateY( -180deg );
    }
  }

/* Loader end */







/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .all_post_area{
        grid-template-columns: repeat(1, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 250px;
        width: 98%;
    }

    .post_outline .content{
        margin-top: 7%;
    }
    
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .all_post_area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 250px;
        width: 98%;
    }
    
    .post_outline .content{
        margin-top: 7%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .all_post_area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 225px;
        width: 98%;
    }
    
    .post_outline .content{
        margin-top: 7%;
    }
}