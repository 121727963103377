
body {
    color: var(--text-color);
    background-color: var(--bg-color);
    /* transition: all 0.3s ease-in-out; */
    transition: margin-left .5s ease-in-out;
    display: flex;
    flex-direction: column;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
    margin-left: auto;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--slider-color);
    transition: all 0.3s ease-in-out;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: var(--post-content-font);
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--hover-color);
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
}

.round {
    border-radius: 34px;
}









/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    /* max-width: 2000px; */
    margin: auto;
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: 0;
    /* border-right: 1px solid var(--hover-color); */
    outline: 1px solid var(--hover-color);
    outline-offset: -1px;
    border-radius: 0 15px 15px 0;
    background-color: var(--bg-color); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s ease-in-out; /* 0.5 second transition effect to slide in the sidenav */
    z-index: 2;
}

/* Nav Menu Starts */

.sidenav .btn,
.sidenav .orderlist a,
.sidenav .orderlist .btn{
    padding: 8px 8px 8px 10px;
    margin: 5px 15px;
    text-decoration: none;
    font-size: 20px;
    color: #a0a0a0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.sidenav .closebtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sidenav .closebtn p{
    font-size: 20px;
    color: #a0a0a0;
    margin: 15px 5px 15px 15px;
}

.sidenav .closebtn i{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: orange;
    border: 1px solid #818181;
    border-radius: 7px;
    margin: 15px 15px 15px 5px;
    font-size: 25px;
}

.sidenav .closebtn i:hover{
    color: #ffffff;
    background-color: orange;
    transition: 0.5s ease-in-out;
    border: 1px solid orange;
}

hr{
    height: 1px;
    width: 90%;
    margin: auto;
    background-color: var(--slider-color);
    border-width: 0px;
}

.sidenav .btn i{
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.sidenav .btn .arrow{
    margin-left: auto;
}

.sidenav .orderlist{
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 35px;
}

.sidenav .orderlist .list-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid var(--slider-color);
    border-radius: 0 7px 7px 0;
    margin: 0 10px 0 0;
}

.sidenav .orderlist .theme-btn,
.sidenav .orderlist .language-btn{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-left: 1px solid var(--slider-color);
    border-radius: 0 7px 7px 0;
    margin: 0 10px 0 0;
}

.sidenav .orderlist .theme-btn p,
.sidenav .orderlist .language-btn p{
    margin-left: 5px;
}

.sidenav .orderlist .list-item:hover,
.sidenav .orderlist .theme-btn:hover,
.sidenav .orderlist .language-btn:hover{
    border-left: 1px solid var(--slider-color);
    border-bottom: 1px solid var(--slider-color);
    border-radius: 0 10px 0 10px;
}

.sidenav .btn p{
    margin-top: 2px;
    margin-left: 4px;
}

.sidenav .btn:hover,
.sidenav .orderlist a:hover,
.sidenav .orderlist .btn:hover{
    /* color: #1777f2; */
    /* color: #1ca1f1; */
    color: #47b5ff;
    background-color: var(--hover-color);    
}

.sidenav .btn i{
    height: 25px;
    width: 25px;
}

/* Nav Menu Ends */






/* ---------------------------------------------------------- */






.main_area{
    position: -webkit-sticky;
    position: sticky;
    top: 0;   /* Actual line to change to floating design*/
    /* border-bottom: 1px solid var(--hover-color); */
}


.header{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    max-width: 2000px;
    margin: auto;
    padding: 0.5% 0;
    /* color: #ffffff; */
    background-color: #47b5ff;
    /* background-color: #1777f2; */
    /* background-color: #1ca1f1; */

    /* background-color: #47b5ff; */
    /*backdrop-filter: blur(5px); /* Adds Glassy background to the header */

    /* background-color: var(--bg-color); */
    /* outline: 1px solid var(--slider-color); */
    /* outline-offset: -1px; */
    border-radius: 0 0 10px 10px;
    position: -webkit-sticky;
    position: sticky;
    
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hamburger_btn{
    width: auto;
    padding: 5px;
    border-radius: 5px;
    margin-left: 15px;
}

.line{
    height: 4px;
    width: 27px;
    background-color: #ffffff;
    /* background-color: #47b5ff; */
    margin-bottom: 5px;
    border-radius: 5px;
}

.line-3{
    width: 27px;
    margin-bottom: 0px;
}

.logo_img{
    margin: 0;    
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 5px;
}

.header h3{
    font-size: 25px;
}









/* Responsive Design */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    /* .main_area{
        top: 1%;
    } */

    .header{
        padding: 2% 0;
        margin: 0 auto;
        /* width: 95%; */
    }
  
    .logo{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    /* .main_area{
        top: 1%;
    } */

    /* .header{
        width: 95%;
    } */
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    /* .main_area{
        top: 1%;
    } */

    .header{
        /* width: 95%; */
        padding: 1% 0;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {
    /* .main_area{
        top: 1%;
    } */

    /* .header{
        width: 95%;
    } */
}
*{
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;

    /* Disable Text Select */
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* Light Mode */
:root {
    /* --gradient-bg-color:  */
    --bg-color: #ffffff;
    --text-color: #222222;
    --slider-color: #ddd;
    --toggle-color: #fff;
    --hover-color: #efefef;
    --post-heading-font: #222222;
    --post-content-font: #616161;
    --post-highlight-bg: #caebff;
    --article-id-color: #6e6e6e;

    --product-bright-color-1: #ddd;
    --product-name: #222222;
    --product-price: #6e6e6e;
    --product-button-bg: #efefef;
    /* --product-dim-color-1: ; */
    
}

/* Dark Mode */
.dark-mode {
    --bg-color: #262626;
    --text-color: #ffffff;
    --slider-color: #444;
    --toggle-color: #ffd700;
    --hover-color: #545454;
    --post-heading-font: #ffffff;
    --post-content-font: #cecece;
    --post-highlight-bg: #64c2fd;
    --article-id-color: #545454;
    
    --product-bright-color-1: #444;
    --product-name: #ffffff;
    --product-price: #cecece;
    --product-button-bg: #444;
}





/*  */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #a4a4a4; 
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #757575;
}
/*  */


/* Footer Starts */
.footer{
    background-color: var(--hover-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2000px;
    width: 100%;
    margin: auto;
}

/* Area 2 */

.footer .area1{
    width: 100%;
    display: grid;
    grid: "foot-logo name b2t";
    grid-template-columns: 50px auto 100px;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px;
    align-items: center;
}

.footer .area1 .footer_logo{
    grid-area: foot-logo;
    width: 50px;
    border-radius: 10px;
    border: 1px solid #777777;
    box-shadow: -2px 2px #47b5ff;
    margin: 20px 0 20px 20px;
}

.footer .area1 h3{
    grid-area: name;
    width: 100%;
    font-size: 30px;
    color: var(--text-color);
}

html{
    scroll-behavior: smooth;
}

.footer .area1 .back2top{
    width: 30px;
    height: 30px;
    border: 1px solid #a6a6a6;
    background-color: #e1e1e1;
    border-radius: 50%;
    grid-area: b2t;
    margin: 0 0 0 0;
}

/* Horizontal Line */
hr{
    height: 1px;
    width: 95%;
    margin: auto;
    background-color: var(--slider-color);
    border-width: 0px;
}


/* Area 2 */

.footer .area2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.footer .version{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 25px 0;
    border-radius: 5px;
    padding: 2px 5px;
    background-color: #a6a6a6;
    box-shadow: -2px 2px #777777;
}

.footer p{
    color: #ffffff;
}

.footer .copy_rights{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 20px;
}

/* Footer Ends */


/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .footer .area1 .footer_logo{
        margin: 20px 0 15px 25px;
    }
    .footer .area1 h3{
        font-size: 1.7em;
    }

    .footer .area1{
        grid-template-columns: 50px auto 70px;
    }
} 
.container {
    position: relative;
    width: 90vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color);
    border-radius: 25px;
    margin: 5% auto;
    z-index: -1;
}
  
.profile-circle {
    /* width: 500px;
    height: 500px;
    border-radius: 50%;
    border: 5px solid transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to right, #ff6b6b, #4dabf7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: rotate 5s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; */
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    /* Pulsating Dotted Glowing Effect */
    box-shadow: 0 0 0 0 rgba(71, 181, 255, 0.6), 0 0 15px 5px rgba(71, 181, 255, 0.6);
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite;
}
  
.profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
  
.bubble {
    position: absolute;
    font-size: 40px;
    font-weight: 700;
    padding: 10px 20px;
    color: var(--text-color);
    background-color: var(--bg-color);
    border: 2px solid var(--slider-color);
    border-radius: 40px;
    font-family: Arial, sans-serif;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    -webkit-animation: float 5s infinite ease-in-out;
            animation: float 5s infinite ease-in-out;
}

.bubble1 {
    top: 20%;
    left: 15%;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
  
.bubble2 {
    top: 15%;
    right: 20%;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
}
  
.bubble3 {
    bottom: 10%;
    left: 10%;
    -webkit-animation-delay: 2s;
            animation-delay: 2s;
}
  
.bubble4 {
    bottom: 20%;
    right: 10%;
    -webkit-animation-delay: 3s;
            animation-delay: 3s;
}

/* Animation for the glowing effect */
@-webkit-keyframes pulse {
    0%, 100% {
        box-shadow: 0 0 0 0 rgba(71, 181, 255, 0.6), 0 0 15px 5px rgba(71, 181, 255, 0.6);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(71, 181, 255, 0.1), 0 0 20px 10px rgba(71, 181, 255, 0.8);
    }
}
@keyframes pulse {
    0%, 100% {
        box-shadow: 0 0 0 0 rgba(71, 181, 255, 0.6), 0 0 15px 5px rgba(71, 181, 255, 0.6);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(71, 181, 255, 0.1), 0 0 20px 10px rgba(71, 181, 255, 0.8);
    }
}
  
@-webkit-keyframes float {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
  
@keyframes float {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
}
  
@-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
  
@keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
  








/* Responsive Design */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait){
    .container{
        height: 75vh;
    }
    
    .profile-circle {
        width: 200px;
        height: 200px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .container{
        height: 75vh;
    }
    
    .profile-circle {
        width: 200px;
        height: 200px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .container{
        height: 80;
    }
    
    .profile-circle {
        width: 300px;
        height: 300px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {
    .container{
        height: 80vh;
    }
    
    .profile-circle {
        width: 300px;
        height: 300px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}
.profile_content{
    max-width: 2000px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color);
}

.profile_content .profile{
    height: 90%;
    width: 95%;
    background-color: #47b5ff;
    text-align: center;
    margin: 2% auto 5%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.profile_content .profile_image{
    margin: 5% auto 2%;
}


.profile_content img{
    width: 300px;
    border-radius: 50%;
}

.profile_content .profile_details{
    width: 100%;
    color: #ffffff;
}

.profile_details .status{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 4px 15px;
    margin: 5px auto 15px;
    border: 1px solid #ffffff;
    box-shadow: -4px 4px #ffffff;
}

.mark2{
    text-decoration: none;
    background-color: #47b5ff;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.2em;   
}


.wave {
    -webkit-animation-name: wave-animation;
            animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;        /* Change to speed up or slow down */
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;  /* Never stop waving :) */
    -webkit-transform-origin: 70% 70%;
            transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
    z-index: -1;
}
  
@-webkit-keyframes wave-animation {
      0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
     10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
     30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
     40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
     50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
     60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}
  
@keyframes wave-animation {
      0% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
     10% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { -webkit-transform: rotate(-8.0deg); transform: rotate(-8.0deg) }
     30% { -webkit-transform: rotate(14.0deg); transform: rotate(14.0deg) }
     40% { -webkit-transform: rotate(-4.0deg); transform: rotate(-4.0deg) }
     50% { -webkit-transform: rotate(10.0deg); transform: rotate(10.0deg) }
     60% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { -webkit-transform: rotate( 0.0deg); transform: rotate( 0.0deg) }
}


.profile_details .intro{
    width: 80%;
    text-align: center;
    margin: 4% auto 4% auto;
}

.profile_details .intro img{
    width: 25px;
}

.profile_details .intro-name{
    font-size: 1.3em;
}

.profile_details .intro-content{
    margin-top: 15px;
    line-height: 23px;
}

.highlight1,
.highlight2{
    text-decoration: underline;
    background-color: #47b5ff;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.3em;
    font-style: italic;
}

.highlight1{
    text-decoration: none;
    font-style: normal;
}

.profile .arrow{
    margin: 2% auto;
    z-index: 0;

}

.profile .arrow i{
    font-size: 25px;
    color: #ffffff;
    background-color: #47b5ff;
    width: 0px;
    border-radius: 5px;
}








/* Tech Stack Start */

.tech_stack{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 800px;
    max-width: 95%;
    margin: auto;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    color: var(--post-heading-font);
    border: 1px solid #777777;
    box-shadow: -5px 5px #47b5ff;
}

.tech_stack .title{
    padding: 8% 5% 2% 5%;
    height: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px 0 0;
    font-size: 3em;
    font-weight: 800;
    text-shadow: -2px 2px #47b5ff;
    /* font-family: 'Rubik Dirt', cursive; */
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 2px;
}

.tech_stack .list{
    height: 100%;
    width: 100%;
    margin: 5% auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 25px;
    row-gap: 25px;
    align-items: center;
    justify-items: center;
    color: var(--text-color);
    font-weight: 600;
}

.tech_stack .area{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tech_stack .logo_title{
    font-size: 1.2em;
    margin: 10px auto 0 auto;
}

.tech_stack .list img{
    height: 50px;
    width: 50px;
    margin: 0 auto;
}

/* Tech Stack Ends */






/* Contact Starts */

.contacts{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 4% auto 5%;
    border-radius: 15px;
    /* border: 1px solid black; */
}

.contacts .ct-title{
    margin: 4% auto 3% auto;
    color: var(--post-heading-font);
    font-size: 3em;
    font-weight: 800;
    text-shadow: -2px 2px #47b5ff;
    /* font-family: 'Rubik Dirt', cursive; */
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 2px;
}

.contacts .stack{
    display: grid;
    grid: 
    "email email email email"
    "insta insta threads threads"
    "insta insta twitter twitter"
    "github linkedin linkedin linkedin";
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    margin: 2% auto;
    height: 100%;
    width: 95%;
    padding: 0 1%;
}

.sep_cnct{
    height: 100%;   
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
    margin: auto;
}

.contacts img{
    width: 30px;
    margin: 5px 0;
}

.contacts p{
    color: #35a853;
    text-decoration: none;
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: 600;
    color: #ffffff;
}

.email{
    grid-area: email;
    background: linear-gradient(45deg, #4285f4 15%, #eb4335 50%,  #35a853 70%, #ffc002 100%);
}

.email p{    
    margin: 10px auto;
}

.instagram{
    grid-area: insta;
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}

.instagram p{
    margin: 0;
}

.threads{
    grid-area: threads;
    color: #ffffff;
    background-color: #000000;
}

.threads img{
    border-radius: 50%;
}

.threads p{
    margin-left: 0.5%;
}

.twitter{
    grid-area: twitter;
    background-color: #03a9f4;
}

.twitter p{
    margin-left: 0.5%;
}

.github{
    grid-area: github;
    background-color: #000000;
    cursor: pointer;
}

.linkedin{
    grid-area: linkedin;
    color: #ffffff;
    background-color: #0077b7;
    cursor: pointer;
}

.linkedin img{
    margin: 5px 0;
    width: 20px;
    height: 20px;
}

/* Contact Ends */




/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .profile_content .profile{
        width: 90%;
        margin: 5% auto 10% auto;
    }

    .profile_content .profile_image{
        margin: 10% auto 2% auto;    
    }
  
    .profile_content img{
        width: 200px;
    }

    .profile_details .status{
        margin: 15px auto 25px;
    }
  
    .profile_details .intro{      
        margin: 4% auto 10% auto;
    }
    
    .profile_details .intro-content{
        margin-top: 20px;
    }

    .profile .arrow{
        margin: 2% auto 5% auto;
    }

    .tech_stack{
        width: 90%;
    }
    
    .tech_stack .title{
        padding: 15% 5% 10% 5%;
    }

    .tech_stack .list{
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
        row-gap: 20px;
    }

    .contacts{
        width: 85%;
        margin: 10% auto;
    }
    
    .contacts .title{
        margin: 5% auto 2% auto;
    }

    .contacts .github{
        width: 40px;
    }

    .contacts .github img{
        width: 25px;
    }

    .sep_cnct p{
        font-size: 1em;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {

}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .profile_content .profile{        
        margin: 3% auto 2% auto;
    }

    .tech_stack{
        margin: 5% auto 0;
    }

    .tech_stack .title{
        padding: 6% 5% 2% 5%;
    }
  
    .contacts{
        width: 85%;
        margin: 5% auto;
    }

}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {

}


@media (min-width: 1281px) /* This and higher is desktop */{

}
.brands-list{
    border: 1px solid black;
    overflow: hidden;
    width: 90%;
    max-width: 2000px;
    margin: auto auto 5% auto;
    border-radius: 10px;
}

.wrapper{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 15rem;
    
    justify-items: stretch;
    margin: 2% auto;
}

@-webkit-keyframes scroll{
    to{
        translate: calc(-4 * 15rem);
    }
}

@keyframes scroll{
    to{
        translate: calc(-4 * 15rem);
    }
}

.wrapper{
    -webkit-animation: scroll 10s linear infinite;
            animation: scroll 10s linear infinite;
}
*{
    margin: 0;
    padding: 0;
}

.body{
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 180% 180%;
	-webkit-animation: Gradient 5s ease infinite;
	        animation: Gradient 5s ease infinite;
}


@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.toggle-page{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

        /* From https://css.glass */
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.switch-field{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--slider-color);
    border-radius: 5px;
    margin: 10px auto 10px;
    padding: 5px;
}

.switch-field button{
    font-size: 15px;
    font-weight: 600;
    padding: 5px 15px;
    background-color: #ffffff;
    border: 1px solid var(--slider-color);
    color: var(--text-color);
    border-radius: 5px;
}

.switch-field .signup-btn{
    margin-left: 5px;
}

.toggle-area{
    width: 400px;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.login-page{
    display: flex;
    flex-direction: column;
}

.login-area{
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 50px 0 0px 0;
    /* border: 1px solid var(--slider-color); */
}

.login-label{
    font-size: 30px;
    font-weight: 600;
    margin: 15px auto 25px;
}

.input-wrapper {
    position: relative;
}

input {
    border: 1px solid gray;
    border-radius: 6px;
    position: relative;
    width: 328px;
    margin: 10px 20px;
    line-height: 45px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0 15px;
}

.input-label {
    position: absolute;
    top: 0.2ex;
    z-index: 1;
    left: 2em;
    background-color: white;
    padding: 0 5px;
    border-radius: 2px;
    font-weight: 600;
}

.login-button{
    background-color: #A8DF8E;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 1.1em;
    font-weight: 600;
    margin: 25px auto;
}

.signup-page{
    display: flex;
    flex-direction: column;
}

.signup-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup-area .clkbtn{
    background-color: #A8DF8E;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 1.1em;
    font-weight: 600;
    margin: 25px auto;    
}
.all_post_area{
    width: 95%;
    max-width: 2000px;
    margin: 4% auto 5%;
    display: grid;    
    grid-template-columns: repeat(3, minmax(200px, 1fr));
}

.post_outline{
    display: flex;
    flex-direction: column;
    margin: 10px;
    color: var(--text-color);
    border: 1px solid var(--slider-color);
    border-radius: 10px;
    text-decoration: none;
    box-shadow: -4px 4px var(--slider-color);
}

.post_outline img{
    grid-area: image;
    height: 300px;
    width: 98%;
    border-radius: 10px;
    border: 1px solid var(--slider-color);
    margin: 1% auto 2%;
}

.post_outline .Heading{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: heading;
}

.post_outline .Heading .ArticleId{
    grid-area: title;
    font-size: 1.5em;
    font-weight: 800;
    margin: 2% 5px 1% 5%;
    padding-right: 10px;
    border-right: 1px solid var(--slider-color);
    /* color:  var(--post-content-font); */
    display: flex;
    align-items: flex-start;
    color: var(--article-id-color);
}

.post_outline .Heading .title{
    grid-area: title;
    font-size: 1.5em;
    font-weight: 800;
    margin: 2% 5%;
    word-spacing: 2px;
    color: var(--post-heading-font);
}

.post_outline .content{
    grid-area: post-content;
    font-size: 1.1em;
    margin: 2% 5%;
    word-spacing: 4px;
    color: var(--post-content-font);
}

.post_outline .category{
    grid-area: category;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-size: 1em;
    margin: 2% 5%;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: #7feb7f;
    justify-items: center;
}

.post_outline .date-time{
    grid-area: date-time;
    margin: 2% 5% 3%;
    color: var(--post-content-font);
}

.post_outline .link{
    grid-area: link;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 8px;
    margin: 1% 5% 5% 5%;
    border-radius: 5px;
    color: var(--text-color);
    background-color: #68c1fc;
    box-shadow: -2px 2px #017fd2;
    text-decoration: none;
}


/* Loader Begins */

.loader {
    width: 200px;
    height: 140px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #979794;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}

.loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
        105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    -webkit-transform: rotateY(0deg );
            transform: rotateY(0deg );
    -webkit-transform-origin: left center;
            transform-origin: left center;
-webkit-animation: paging 1s linear infinite;
        animation: paging 1s linear infinite;
  }


@-webkit-keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
  }


@keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
  }

/* Loader end */







/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .all_post_area{
        grid-template-columns: repeat(1, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 250px;
        width: 98%;
    }

    .post_outline .content{
        margin-top: 7%;
    }
    
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .all_post_area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 250px;
        width: 98%;
    }
    
    .post_outline .content{
        margin-top: 7%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .all_post_area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .post_outline img{
        height: 225px;
        width: 98%;
    }
    
    .post_outline .content{
        margin-top: 7%;
    }
}
.adj-post-area{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
    
    border-top: 1px solid var(--slider-color);    
    margin: 5% auto 2%;
}

.prev-post{
    width: 300px;
    margin: 1%;
    text-decoration: none;
    overflow: hidden;
}

.next-post{
    width: 300px;
    margin: 1% 1% 1% auto;
    text-decoration: none;
    overflow: hidden;
}

.prev-post p{
    margin: 5px;
}


.next-post p{
    text-align: right;
    margin: 5px;
}

.post-title{
    font-size: 1em;
    color: var(--post-content-font);
}

.post-name{
    font-size: 1.5em;
    font-weight: 600;
    color: var(--post-heading-font);
}




/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .adj-post-area{    
        margin: 1% auto 10%;
    }
}


@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .adj-post-area{    
        margin: 1% auto 10%;
    }
}


@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{    
    .adj-post-area{    
        margin: 1% auto 5%;
    }
}
.post-area{
    margin: auto;
    padding: auto;
    max-width: 2000px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}


.title{
    width: 1000px;
    margin: 5% auto 1%;
    font-size: 2em;
    font-weight: 800;
    color: var(--post-heading-font);
}

.excerpt{
    width: 1000px;
    font-size: 1.3em;
    margin: 1.5% auto;
    text-align: justify;
    color: var(--post-content-font);
}

.cover-image{
    height: 60vh;
    width: 50vw;
    display: block;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    border-radius: 15px;
    border: 1px solid var(--slider-color);
}

.other-details{
    width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2% auto;
    color: var(--post-content-font);
    /* margin: 2% auto; */
}

.author-img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 15px;
}

.author{
    margin-left: 10px;
}

.hr-line{
    width: 70%;
}

.rich-text-comp{
    flex: 50% 1;
}

.rt{
    width: 1000px;
    /* margin: 2% auto 1%; */
    line-height: 1.5em;
    /* border: 1px solid black; */
}

.rt img{
    border-radius: 15px;
    border: 1px solid var(--slider-color);
}

.rt-para{
    margin: 1% auto;
    color: var(--post-content-font);
    font-size: 1.1em;
}

.rt-para:last-child{
    margin-bottom: 4%;
}

.rt-h1{
    font-size: 2.2em;
    font-weight: 700;
    color: var(--post-heading-font);
    margin: 4% auto 0.5% auto;
}

.rt-h2{
    font-size: 1.6em;
    font-weight: 700;
    margin: 1% auto;
    color: var(--post-heading-font);
}

.rt-h3{
    font-size: 1.2em;
    margin: 1% auto;
    color: var(--post-heading-font);
}

.rt-ol,
.rt-ul{
    margin: 2% auto 1% auto;
    color: var(--post-content-font);
}

.rt-li{
    margin: 0.5% auto;
    color: var(--post-content-font);
    font-size: 1.1em;
    list-style-position: inside;
}

.rt-li:first-child{
    margin-top: 2%;
}

.rt-li:last-child{
    margin-bottom: 2%;
}

.rt-img{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 1% auto;
    /* cursor: not-allowed; */
    cursor: zoom-in;
}

.rt-img img{
    height: 50vh;
    width: 40vw;
    object-fit:cover;
    display: inline;
}

.rt-img-title{
    font-size: 1em;
    margin: 2% auto;
    color: var(--post-content-font);
}

.rt-link{
    font-size: 1em;
    color: #5fb1ef;
}




/* Photo Collection Starts */

.image-post-area{
    height: 50vh;
    width: 40vw;
    margin: 5% auto 10%;
}

.image-details{
    display: flex;
    flex-direction: column;
}

.image-post-area .image-post-view{
    height: 50vh;
    width: 40vw;
    border-radius: 15px;
    border: 1px solid var(--slider-color);
    object-fit: contain;
}

.image-title{
    margin: 1% 1% 4% 0%;
    font-size: 1.1em;
    font-style: italic;
    color: var(--post-heading-font);
}

.image-post-controls{
    margin: auto;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.image-post-controls button{
    height: 30px;
    width: 25px;
    padding-top: 1px;
    background-color: var(--bg-color);
    border: 1px solid var(--slider-color);
}

.image-post-controls button i{
    color: var(--post-content-font);
    margin-top: 50px;
    font-size: 25px;
}

.image-post-controls .image-post-number{
    margin: auto 0;
    padding: 2.5px 25px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px;
    font-weight: 700;
    color: var(--post-content-font);
    border: 1px solid var(--slider-color);
    border-left: 0px;
    border-right: 0px;
}

.image-post-controls .prev-post-btn{
    border-radius: 15px 0 0 15px;
    border-right: 0px;
}

.image-post-controls .next-post-btn{
    border-radius: 0 15px 15px 0;
    border-left: 0px;
}


/* Photo Collection Ends */




/* Loader Begins */

.loader {
    width: 200px;
    height: 140px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #979794;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}

.loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
        105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    -webkit-transform: rotateY(0deg );
            transform: rotateY(0deg );
    -webkit-transform-origin: left center;
            transform-origin: left center;
-webkit-animation: paging 1s linear infinite;
        animation: paging 1s linear infinite;
  }


@-webkit-keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
  }


@keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
  }

/* Loader end */








/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 30vh;
        width: 100%;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.05em;
        margin: 5% auto;
        line-height: 1.5em;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 10% 0 15% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }
    
    .rt-li{
        margin: 2% auto;
        text-align: justify;
        
    }
    
    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-para:last-child{
        margin-bottom: 10%;
    }

    .rt-img{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 25vh;
        width: 100%;
    }

    .rt-h1{
        margin: 15% auto 0.5% auto;
        line-height: 1em;
    }

    .rt-h2{
        font-size: 1.5em;
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .rt-h3{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    /* .rt-h1, .rt-h2, .rt-h3, .rt-ul, .rt-ol, .rt-li{        
    } */ 
    
    .image-post-area{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
    }

    .image-post-area .image-post-view{
        height: 30vh;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 50%;
        width: 100%;
        border: 1px solid black;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.05em;
        margin: 5% auto;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 5% 0 10% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }

    .rt-li{
        margin: 2% auto;
        text-align: justify;
    }

    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-img{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 50%;
        width: 70vw;
    }

    .rt-h1{
        margin: 15% auto 0.5% auto;
        line-height: 1em;
    }

    .rt-h2{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .rt-h3{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .image-post-area{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 100%;
    }

    .image-post-area .image-post-view{
        height: 30vh;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{    
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 40%;
        width: 70vw;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.1em;
        margin: 3% auto;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 5% 0 10% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }

    .rt-li{
        margin: 2% auto;
        text-align: justify;
    }

    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-img{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 40%;
        width: 70vw;
    }
    
    .image-post-area{
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        width: 70%;
    }

    .image-post-area .image-post-view{
        height: 35vh;
        width: 100%;
        object-fit: contain;
    }
}
.dashed-lines-container {
  display: flex;
  justify-content: space-between; /* Optional, keeps spacing consistent */
  width: 100%; /* The parent div takes full width */
}

.dashed-line {
  flex-grow: 1; /* Each line adjusts dynamically to fill available space */
  height: 3px;
  margin: 0 2px; /* Adds spacing between lines */
  background-color: #ddd;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.dashed-line.highlighted {
  background-color: #47b5ff;
}

.all-product-area{    
    width: 95%;
    max-width: 2000px;
    margin: 4% auto 5%;
    display: grid;    
    grid-template-columns: repeat(3, minmax(200px, 1fr));
}


.product-card{
    display: flex;
    flex-direction: column;
    margin: 10px;
    color: var(--text-color);
    border: 1px solid var(--slider-color);
    border-radius: 10px;
    text-decoration: none;
    box-shadow: -4px 4px var(--slider-color);    
}

.product-card  .image{
    grid-area: image;
    height: 300px;
    width: 98%;
    border-radius: 10px;
    border: 1px solid var(--slider-color);
    margin: 1% auto 2%;
}

.product-card .Heading{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-area: heading;
}

.product-card .title{
    grid-area: title;
    font-size: 1.5em;
    font-weight: 800;
    margin: 2% 5%;
    word-spacing: 2px;
    color: var(--post-heading-font);
}

.product-card .price{
    grid-area: date-time;
    margin: 2% 5% 3%;
    color: var(--post-content-font);
}


/* Loader design starts */
.loader {
    width: 200px;
    height: 140px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: #979794;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    -webkit-perspective: 1000px;
            perspective: 1000px;
}

.loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
        105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    -webkit-transform: rotateY(0deg );
            transform: rotateY(0deg );
    -webkit-transform-origin: left center;
            transform-origin: left center;
-webkit-animation: paging 1s linear infinite;
        animation: paging 1s linear infinite;
}


@-webkit-keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
}


@keyframes paging {
    to {
        -webkit-transform: rotateY( -180deg );
                transform: rotateY( -180deg );
    }
}
/* Loader design ends */













/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .all-product-area{
        grid-template-columns: repeat(1, minmax(150px, 1fr));
    }
    
    .product-card img{
        height: 250px;
        width: 98%;
    }    
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .all-product-area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .product-card img{
        height: 250px;
        width: 98%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .all-product-area{
        grid-template-columns: repeat(2, minmax(150px, 1fr));
    }
    
    .product-card img{
        height: 225px;
        width: 98%;
    }
}
.product-area{
    max-width: 2000px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid black;
    padding: 1%;
}

.image-area{
    width: 500px;
    border: 1px solid black;
}

.image-area .image-view{
    width: 500px;
    border: 1px solid black;
}

.image-controls{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    border-top: 1px solid black;
    padding: 5px;
}

.image-controls button{
    height: 25px;
    width: 25px;
    padding-top: 1px;
    background-color: var(--bg-color);
    border: 1px solid var(--post-content-font);
    border-radius: 10px;
}

.image-controls button i{
    color: var(--post-content-font);
    margin-top: 50px;
    font-size: 20px;
}

.image-controls .image-number{
    margin: auto;
    font-size: 1.1em;
    font-weight: 700;
    color: var(--post-content-font);
}

.product-details{
    width: 500px;
    border: 1px solid black;
}

.product-details .pdt-name{
    margin: 5% 1% 1% -5%;
    padding: 0;
    font-size: 3em;
    font-weight: 800;
    -webkit-transform: scale(0.8, 1);
            transform: scale(0.8, 1);
    letter-spacing: -2px;
    line-height: 50px;
    color: var(--post-heading-font);
}

.product-details .pdt-price{
    margin: 5px 0 20px 20px;
    font-size: 1em;
    font-weight: 700;
    color: var(--post-heading-font);    
}








/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .product-area{
        flex-direction: column;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }
    
    .product-details .pdt-name{
        margin: 2%;
    }

    .product-details{
        width: 98%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .product-area{
        flex-direction: column;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }

    .product-details{
        width: 98%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .product-area{
        flex-direction: column;
        grid-column-gap: 10px;
        -webkit-column-gap: 10px;
                column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }

    .product-details{
        width: 95%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}

