.post-area{
    margin: auto;
    padding: auto;
    max-width: 2000px;
    height: fit-content;
}


.title{
    width: 1000px;
    margin: 5% auto 1%;
    font-size: 2em;
    font-weight: 800;
    color: var(--post-heading-font);
}

.excerpt{
    width: 1000px;
    font-size: 1.3em;
    margin: 1.5% auto;
    text-align: justify;
    color: var(--post-content-font);
}

.cover-image{
    height: 60vh;
    width: 50vw;
    display: block;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    border-radius: 15px;
    border: 1px solid var(--slider-color);
}

.other-details{
    width: 1000px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2% auto;
    color: var(--post-content-font);
    /* margin: 2% auto; */
}

.author-img{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 15px;
}

.author{
    margin-left: 10px;
}

.hr-line{
    width: 70%;
}

.rich-text-comp{
    flex: 50%;
}

.rt{
    width: 1000px;
    /* margin: 2% auto 1%; */
    line-height: 1.5em;
    /* border: 1px solid black; */
}

.rt img{
    border-radius: 15px;
    border: 1px solid var(--slider-color);
}

.rt-para{
    margin: 1% auto;
    color: var(--post-content-font);
    font-size: 1.1em;
}

.rt-para:last-child{
    margin-bottom: 4%;
}

.rt-h1{
    font-size: 2.2em;
    font-weight: 700;
    color: var(--post-heading-font);
    margin: 4% auto 0.5% auto;
}

.rt-h2{
    font-size: 1.6em;
    font-weight: 700;
    margin: 1% auto;
    color: var(--post-heading-font);
}

.rt-h3{
    font-size: 1.2em;
    margin: 1% auto;
    color: var(--post-heading-font);
}

.rt-ol,
.rt-ul{
    margin: 2% auto 1% auto;
    color: var(--post-content-font);
}

.rt-li{
    margin: 0.5% auto;
    color: var(--post-content-font);
    font-size: 1.1em;
    list-style-position: inside;
}

.rt-li:first-child{
    margin-top: 2%;
}

.rt-li:last-child{
    margin-bottom: 2%;
}

.rt-img{
    width: fit-content;
    margin: 1% auto;
    /* cursor: not-allowed; */
    cursor: zoom-in;
}

.rt-img img{
    height: 50vh;
    width: 40vw;
    object-fit:cover;
    display: inline;
}

.rt-img-title{
    font-size: 1em;
    margin: 2% auto;
    color: var(--post-content-font);
}

.rt-link{
    font-size: 1em;
    color: #5fb1ef;
}




/* Photo Collection Starts */

.image-post-area{
    height: 50vh;
    width: 40vw;
    margin: 5% auto 10%;
}

.image-details{
    display: flex;
    flex-direction: column;
}

.image-post-area .image-post-view{
    height: 50vh;
    width: 40vw;
    border-radius: 15px;
    border: 1px solid var(--slider-color);
    object-fit: contain;
}

.image-title{
    margin: 1% 1% 4% 0%;
    font-size: 1.1em;
    font-style: italic;
    color: var(--post-heading-font);
}

.image-post-controls{
    margin: auto;
    padding: 10px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}

.image-post-controls button{
    height: 30px;
    width: 25px;
    padding-top: 1px;
    background-color: var(--bg-color);
    border: 1px solid var(--slider-color);
}

.image-post-controls button i{
    color: var(--post-content-font);
    margin-top: 50px;
    font-size: 25px;
}

.image-post-controls .image-post-number{
    margin: auto 0;
    padding: 2.5px 25px;
    height: fit-content;
    width: fit-content;
    font-size: 20px;
    font-weight: 700;
    color: var(--post-content-font);
    border: 1px solid var(--slider-color);
    border-left: 0px;
    border-right: 0px;
}

.image-post-controls .prev-post-btn{
    border-radius: 15px 0 0 15px;
    border-right: 0px;
}

.image-post-controls .next-post-btn{
    border-radius: 0 15px 15px 0;
    border-left: 0px;
}


/* Photo Collection Ends */




/* Loader Begins */

.loader {
    width: 200px;
    height: 140px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #979794;
    box-sizing: border-box;
    position: absolute;
    border-radius:8px;
    perspective: 1000px;
}

.loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0), 
        linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
        105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

.loader:after {
    content: '';
    position: absolute;
    width: calc(50% - 10px);
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius: 8px;
    background: #fff no-repeat;
    background-size: 60px 10px;
    background-image: linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0), 
            linear-gradient(#ddd 100px, transparent 0);
    background-position: 50% 30px ,50% 60px , 50%  90px;
    transform: rotateY(0deg );
    transform-origin: left center;
animation: paging 1s linear infinite;
  }


@keyframes paging {
    to {
        transform: rotateY( -180deg );
    }
  }

/* Loader end */








/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 30vh;
        width: 100%;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.05em;
        margin: 5% auto;
        line-height: 1.5em;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 10% 0 15% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }
    
    .rt-li{
        margin: 2% auto;
        text-align: justify;
        
    }
    
    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-para:last-child{
        margin-bottom: 10%;
    }

    .rt-img{
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 25vh;
        width: 100%;
    }

    .rt-h1{
        margin: 15% auto 0.5% auto;
        line-height: 1em;
    }

    .rt-h2{
        font-size: 1.5em;
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .rt-h3{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    /* .rt-h1, .rt-h2, .rt-h3, .rt-ul, .rt-ol, .rt-li{        
    } */ 
    
    .image-post-area{
        height: fit-content;
        width: 100%;
    }

    .image-post-area .image-post-view{
        height: 30vh;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 50%;
        width: 100%;
        border: 1px solid black;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.05em;
        margin: 5% auto;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 5% 0 10% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }

    .rt-li{
        margin: 2% auto;
        text-align: justify;
    }

    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-img{
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 50%;
        width: 70vw;
    }

    .rt-h1{
        margin: 15% auto 0.5% auto;
        line-height: 1em;
    }

    .rt-h2{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .rt-h3{
        line-height: 1.3em;
        margin: 10% auto 0.5% auto;
    }
    
    .image-post-area{
        height: fit-content;
        width: 100%;
    }

    .image-post-area .image-post-view{
        height: 30vh;
        width: 100%;
        object-fit: contain;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{    
    .post-area{
        width: 95%;
    }

    .title{
        width: 95%;
    }

    .cover-image{
        height: 40%;
        width: 70vw;
        margin-top: 10%;
    }
    
    .excerpt{
        width: 95%;
        font-size: 1.1em;
        margin: 3% auto;
    }    

    .other-details{
        width: 95%;
    }

    .hr-line{
        width: 90%;
        margin: 5% 0 10% 0;
    }

    .rt{
        width: 95%;
        margin: 5% auto 2% auto;
    }

    .rt-li{
        margin: 2% auto;
        text-align: justify;
    }

    .rt-para{
        line-height: 1.5em;
        text-align: justify;
    }

    .rt-img{
        width: fit-content;
        margin: 1% auto;
    }
    
    .rt-img img{
        height: 40%;
        width: 70vw;
    }
    
    .image-post-area{
        height: fit-content;
        width: 70%;
    }

    .image-post-area .image-post-view{
        height: 35vh;
        width: 100%;
        object-fit: contain;
    }
}