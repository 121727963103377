.product-area{
    max-width: 2000px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border: 1px solid black;
    padding: 1%;
}

.image-area{
    width: 500px;
    border: 1px solid black;
}

.image-area .image-view{
    width: 500px;
    border: 1px solid black;
}

.image-controls{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    border-top: 1px solid black;
    padding: 5px;
}

.image-controls button{
    height: 25px;
    width: 25px;
    padding-top: 1px;
    background-color: var(--bg-color);
    border: 1px solid var(--post-content-font);
    border-radius: 10px;
}

.image-controls button i{
    color: var(--post-content-font);
    margin-top: 50px;
    font-size: 20px;
}

.image-controls .image-number{
    margin: auto;
    font-size: 1.1em;
    font-weight: 700;
    color: var(--post-content-font);
}

.product-details{
    width: 500px;
    border: 1px solid black;
}

.product-details .pdt-name{
    margin: 5% 1% 1% -5%;
    padding: 0;
    font-size: 3em;
    font-weight: 800;
    transform: scale(0.8, 1);
    letter-spacing: -2px;
    line-height: 50px;
    color: var(--post-heading-font);
}

.product-details .pdt-price{
    margin: 5px 0 20px 20px;
    font-size: 1em;
    font-weight: 700;
    color: var(--post-heading-font);    
}








/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .product-area{
        flex-direction: column;
        column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }
    
    .product-details .pdt-name{
        margin: 2%;
    }

    .product-details{
        width: 98%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .product-area{
        flex-direction: column;
        column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }

    .product-details{
        width: 98%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .product-area{
        flex-direction: column;
        column-gap: 10px;
    }

    .image-area{
        margin: 10% auto 5%;
        width: 85%;
    }

    .image-area .image-view{
        width: 100%;
    }

    .product-details{
        width: 95%;
        margin: auto;
    }

    .product-details .pdt-price{
        margin: 1% 2% 5% 2%;
    }
}