.container {
    position: relative;
    width: 90vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-color);
    border-radius: 25px;
    margin: 5% auto;
    z-index: -1;
}
  
.profile-circle {
    /* width: 500px;
    height: 500px;
    border-radius: 50%;
    border: 5px solid transparent;
    background-image: linear-gradient(white, white),
      linear-gradient(to right, #ff6b6b, #4dabf7);
    background-origin: border-box;
    background-clip: content-box, border-box;
    animation: rotate 5s linear infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; */
    position: relative;
    width: 500px;
    height: 500px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    /* Pulsating Dotted Glowing Effect */
    box-shadow: 0 0 0 0 rgba(71, 181, 255, 0.6), 0 0 15px 5px rgba(71, 181, 255, 0.6);
    animation: pulse 2s infinite;
}
  
.profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
  
.bubble {
    position: absolute;
    font-size: 40px;
    font-weight: 700;
    padding: 10px 20px;
    color: var(--text-color);
    background-color: var(--bg-color);
    border: 2px solid var(--slider-color);
    border-radius: 40px;
    font-family: Arial, sans-serif;
    backdrop-filter: blur(5px);
    animation: float 5s infinite ease-in-out;
}

.bubble1 {
    top: 20%;
    left: 15%;
    animation-delay: 0s;
}
  
.bubble2 {
    top: 15%;
    right: 20%;
    animation-delay: 1s;
}
  
.bubble3 {
    bottom: 10%;
    left: 10%;
    animation-delay: 2s;
}
  
.bubble4 {
    bottom: 20%;
    right: 10%;
    animation-delay: 3s;
}

/* Animation for the glowing effect */
@keyframes pulse {
    0%, 100% {
        box-shadow: 0 0 0 0 rgba(71, 181, 255, 0.6), 0 0 15px 5px rgba(71, 181, 255, 0.6);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(71, 181, 255, 0.1), 0 0 20px 10px rgba(71, 181, 255, 0.8);
    }
}
  
@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
}
  
@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  








/* Responsive Design */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait){
    .container{
        height: 75vh;
    }
    
    .profile-circle {
        width: 200px;
        height: 200px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .container{
        height: 75vh;
    }
    
    .profile-circle {
        width: 200px;
        height: 200px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .container{
        height: 80;
    }
    
    .profile-circle {
        width: 300px;
        height: 300px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {
    .container{
        height: 80vh;
    }
    
    .profile-circle {
        width: 300px;
        height: 300px;
    }

    .bubble{
        font-size: 20px;
    }

    .bubble1 {
        top: 15%;
        left: 10%;
    }
      
    .bubble2 {
        top: 10%;
        right: 15%;
    }
      
    .bubble3 {
        bottom: 5%;
        left: 5%;
    }
      
    .bubble4 {
        bottom: 15%;
        right: 10%;
    }
}