/* Footer Starts */
.footer{
    background-color: var(--hover-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2000px;
    width: 100%;
    margin: auto;
}

/* Area 2 */

.footer .area1{
    width: 100%;
    display: grid;
    grid: "foot-logo name b2t";
    grid-template-columns: 50px auto 100px;
    column-gap: 40px;
    align-items: center;
}

.footer .area1 .footer_logo{
    grid-area: foot-logo;
    width: 50px;
    border-radius: 10px;
    border: 1px solid #777777;
    box-shadow: -2px 2px #47b5ff;
    margin: 20px 0 20px 20px;
}

.footer .area1 h3{
    grid-area: name;
    width: 100%;
    font-size: 30px;
    color: var(--text-color);
}

html{
    scroll-behavior: smooth;
}

.footer .area1 .back2top{
    width: 30px;
    height: 30px;
    border: 1px solid #a6a6a6;
    background-color: #e1e1e1;
    border-radius: 50%;
    grid-area: b2t;
    margin: 0 0 0 0;
}

/* Horizontal Line */
hr{
    height: 1px;
    width: 95%;
    margin: auto;
    background-color: var(--slider-color);
    border-width: 0px;
}


/* Area 2 */

.footer .area2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.footer .version{
    height: fit-content;
    width: fit-content;
    margin: 25px 0;
    border-radius: 5px;
    padding: 2px 5px;
    background-color: #a6a6a6;
    box-shadow: -2px 2px #777777;
}

.footer p{
    color: #ffffff;
}

.footer .copy_rights{
    width: fit-content;
    margin-left: 20px;
}

/* Footer Ends */


/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .footer .area1 .footer_logo{
        margin: 20px 0 15px 25px;
    }
    .footer .area1 h3{
        font-size: 1.7em;
    }

    .footer .area1{
        grid-template-columns: 50px auto 70px;
    }
} 