.adj-post-area{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1000px;
    
    border-top: 1px solid var(--slider-color);    
    margin: 5% auto 2%;
}

.prev-post{
    width: 300px;
    margin: 1%;
    text-decoration: none;
    overflow: hidden;
}

.next-post{
    width: 300px;
    margin: 1% 1% 1% auto;
    text-decoration: none;
    overflow: hidden;
}

.prev-post p{
    margin: 5px;
}


.next-post p{
    text-align: right;
    margin: 5px;
}

.post-title{
    font-size: 1em;
    color: var(--post-content-font);
}

.post-name{
    font-size: 1.5em;
    font-weight: 600;
    color: var(--post-heading-font);
}




/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .adj-post-area{    
        margin: 1% auto 10%;
    }
}


@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    .adj-post-area{    
        margin: 1% auto 10%;
    }
}


@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{    
    .adj-post-area{    
        margin: 1% auto 5%;
    }
}