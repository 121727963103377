*{
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', sans-serif;

    /* Disable Text Select */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

/* Light Mode */
:root {
    /* --gradient-bg-color:  */
    --bg-color: #ffffff;
    --text-color: #222222;
    --slider-color: #ddd;
    --toggle-color: #fff;
    --hover-color: #efefef;
    --post-heading-font: #222222;
    --post-content-font: #616161;
    --post-highlight-bg: #caebff;
    --article-id-color: #6e6e6e;

    --product-bright-color-1: #ddd;
    --product-name: #222222;
    --product-price: #6e6e6e;
    --product-button-bg: #efefef;
    /* --product-dim-color-1: ; */
    
}

/* Dark Mode */
.dark-mode {
    --bg-color: #262626;
    --text-color: #ffffff;
    --slider-color: #444;
    --toggle-color: #ffd700;
    --hover-color: #545454;
    --post-heading-font: #ffffff;
    --post-content-font: #cecece;
    --post-highlight-bg: #64c2fd;
    --article-id-color: #545454;
    
    --product-bright-color-1: #444;
    --product-name: #ffffff;
    --product-price: #cecece;
    --product-button-bg: #444;
}





/*  */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #a4a4a4; 
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #757575;
}
/*  */

