.profile_content{
    max-width: 2000px;
    height: fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    background-color: var(--bg-color);
}

.profile_content .profile{
    height: 90%;
    width: 95%;
    background-color: #47b5ff;
    text-align: center;
    margin: 2% auto 5%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
}

.profile_content .profile_image{
    margin: 5% auto 2%;
}


.profile_content img{
    width: 300px;
    border-radius: 50%;
}

.profile_content .profile_details{
    width: 100%;
    color: #ffffff;
}

.profile_details .status{
    width: fit-content;
    padding: 4px 15px;
    margin: 5px auto 15px;
    border: 1px solid #ffffff;
    box-shadow: -4px 4px #ffffff;
}

.mark2{
    text-decoration: none;
    background-color: #47b5ff;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.2em;   
}


.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
    z-index: -1;
}
  
@keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
}


.profile_details .intro{
    width: 80%;
    text-align: center;
    margin: 4% auto 4% auto;
}

.profile_details .intro img{
    width: 25px;
}

.profile_details .intro-name{
    font-size: 1.3em;
}

.profile_details .intro-content{
    margin-top: 15px;
    line-height: 23px;
}

.highlight1,
.highlight2{
    text-decoration: underline;
    background-color: #47b5ff;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.3em;
    font-style: italic;
}

.highlight1{
    text-decoration: none;
    font-style: normal;
}

.profile .arrow{
    margin: 2% auto;
    z-index: 0;

}

.profile .arrow i{
    font-size: 25px;
    color: #ffffff;
    background-color: #47b5ff;
    width: 0px;
    border-radius: 5px;
}








/* Tech Stack Start */

.tech_stack{
    height: fit-content;
    width: 800px;
    max-width: 95%;
    margin: auto;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    color: var(--post-heading-font);
    border: 1px solid #777777;
    box-shadow: -5px 5px #47b5ff;
}

.tech_stack .title{
    padding: 8% 5% 2% 5%;
    height: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px 15px 0 0;
    font-size: 3em;
    font-weight: 800;
    text-shadow: -2px 2px #47b5ff;
    /* font-family: 'Rubik Dirt', cursive; */
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 2px;
}

.tech_stack .list{
    height: 100%;
    width: 100%;
    margin: 5% auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 25px;
    align-items: center;
    justify-items: center;
    color: var(--text-color);
    font-weight: 600;
}

.tech_stack .area{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tech_stack .logo_title{
    font-size: 1.2em;
    margin: 10px auto 0 auto;
}

.tech_stack .list img{
    height: 50px;
    width: 50px;
    margin: 0 auto;
}

/* Tech Stack Ends */






/* Contact Starts */

.contacts{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin: 4% auto 5%;
    border-radius: 15px;
    /* border: 1px solid black; */
}

.contacts .ct-title{
    margin: 4% auto 3% auto;
    color: var(--post-heading-font);
    font-size: 3em;
    font-weight: 800;
    text-shadow: -2px 2px #47b5ff;
    /* font-family: 'Rubik Dirt', cursive; */
    font-family: 'Bebas Neue', sans-serif;
    letter-spacing: 2px;
}

.contacts .stack{
    display: grid;
    grid: 
    "email email email email"
    "insta insta threads threads"
    "insta insta twitter twitter"
    "github linkedin linkedin linkedin";
    row-gap: 15px;
    column-gap: 15px;
    margin: 2% auto;
    height: 100%;
    width: 95%;
    padding: 0 1%;
}

.sep_cnct{
    height: 100%;   
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
    margin: auto;
}

.contacts img{
    width: 30px;
    margin: 5px 0;
}

.contacts p{
    color: #35a853;
    text-decoration: none;
    margin-left: 10px;
    font-size: 1.2em;
    font-weight: 600;
    color: #ffffff;
}

.email{
    grid-area: email;
    background: linear-gradient(45deg, #4285f4 15%, #eb4335 50%,  #35a853 70%, #ffc002 100%);
}

.email p{    
    margin: 10px auto;
}

.instagram{
    grid-area: insta;
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
}

.instagram p{
    margin: 0;
}

.threads{
    grid-area: threads;
    color: #ffffff;
    background-color: #000000;
}

.threads img{
    border-radius: 50%;
}

.threads p{
    margin-left: 0.5%;
}

.twitter{
    grid-area: twitter;
    background-color: #03a9f4;
}

.twitter p{
    margin-left: 0.5%;
}

.github{
    grid-area: github;
    background-color: #000000;
    cursor: pointer;
}

.linkedin{
    grid-area: linkedin;
    color: #ffffff;
    background-color: #0077b7;
    cursor: pointer;
}

.linkedin img{
    margin: 5px 0;
    width: 20px;
    height: 20px;
}

/* Contact Ends */




/* -------------  Responsive Design   -------------- */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    .profile_content .profile{
        width: 90%;
        margin: 5% auto 10% auto;
    }

    .profile_content .profile_image{
        margin: 10% auto 2% auto;    
    }
  
    .profile_content img{
        width: 200px;
    }

    .profile_details .status{
        margin: 15px auto 25px;
    }
  
    .profile_details .intro{      
        margin: 4% auto 10% auto;
    }
    
    .profile_details .intro-content{
        margin-top: 20px;
    }

    .profile .arrow{
        margin: 2% auto 5% auto;
    }

    .tech_stack{
        width: 90%;
    }
    
    .tech_stack .title{
        padding: 15% 5% 10% 5%;
    }

    .tech_stack .list{
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }

    .contacts{
        width: 85%;
        margin: 10% auto;
    }
    
    .contacts .title{
        margin: 5% auto 2% auto;
    }

    .contacts .github{
        width: 40px;
    }

    .contacts .github img{
        width: 25px;
    }

    .sep_cnct p{
        font-size: 1em;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {

}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    .profile_content .profile{        
        margin: 3% auto 2% auto;
    }

    .tech_stack{
        margin: 5% auto 0;
    }

    .tech_stack .title{
        padding: 6% 5% 2% 5%;
    }
  
    .contacts{
        width: 85%;
        margin: 5% auto;
    }

}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {

}


@media (min-width: 1281px) /* This and higher is desktop */{

}