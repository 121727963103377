*{
    margin: 0;
    padding: 0;
}

.body{
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 180% 180%;
	animation: Gradient 5s ease infinite;
}


@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.toggle-page{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

        /* From https://css.glass */
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.switch-field{
    width: fit-content;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--slider-color);
    border-radius: 5px;
    margin: 10px auto 10px;
    padding: 5px;
}

.switch-field button{
    font-size: 15px;
    font-weight: 600;
    padding: 5px 15px;
    background-color: #ffffff;
    border: 1px solid var(--slider-color);
    color: var(--text-color);
    border-radius: 5px;
}

.switch-field .signup-btn{
    margin-left: 5px;
}

.toggle-area{
    width: 400px;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.login-page{
    display: flex;
    flex-direction: column;
}

.login-area{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    margin: 50px 0 0px 0;
    /* border: 1px solid var(--slider-color); */
}

.login-label{
    font-size: 30px;
    font-weight: 600;
    margin: 15px auto 25px;
}

.input-wrapper {
    position: relative;
}

input {
    border: 1px solid gray;
    border-radius: 6px;
    position: relative;
    width: 328px;
    margin: 10px 20px;
    line-height: 45px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 0 15px;
}

.input-label {
    position: absolute;
    top: 0.2ex;
    z-index: 1;
    left: 2em;
    background-color: white;
    padding: 0 5px;
    border-radius: 2px;
    font-weight: 600;
}

.login-button{
    background-color: #A8DF8E;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 1.1em;
    font-weight: 600;
    margin: 25px auto;
}

.signup-page{
    display: flex;
    flex-direction: column;
}

.signup-area{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signup-area .clkbtn{
    background-color: #A8DF8E;
    border: none;
    border-radius: 5px;
    padding: 5px 20px;
    font-size: 1.1em;
    font-weight: 600;
    margin: 25px auto;    
}