
body {
    color: var(--text-color);
    background-color: var(--bg-color);
    /* transition: all 0.3s ease-in-out; */
    transition: margin-left .5s ease-in-out;
    display: flex;
    flex-direction: column;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 24px;
    margin-left: auto;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--slider-color);
    transition: all 0.3s ease-in-out;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: var(--post-content-font);
    transition: all 0.3s ease-in-out;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: var(--hover-color);
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.round {
    border-radius: 34px;
}









/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    /* max-width: 2000px; */
    margin: auto;
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: 0;
    /* border-right: 1px solid var(--hover-color); */
    outline: 1px solid var(--hover-color);
    outline-offset: -1px;
    border-radius: 0 15px 15px 0;
    background-color: var(--bg-color); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s ease-in-out; /* 0.5 second transition effect to slide in the sidenav */
    z-index: 2;
}

/* Nav Menu Starts */

.sidenav .btn,
.sidenav .orderlist a,
.sidenav .orderlist .btn{
    padding: 8px 8px 8px 10px;
    margin: 5px 15px;
    text-decoration: none;
    font-size: 20px;
    color: #a0a0a0;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.sidenav .closebtn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sidenav .closebtn p{
    font-size: 20px;
    color: #a0a0a0;
    margin: 15px 5px 15px 15px;
}

.sidenav .closebtn i{
    display: flex;
    align-items: center;
    width: fit-content;
    color: orange;
    border: 1px solid #818181;
    border-radius: 7px;
    margin: 15px 15px 15px 5px;
    font-size: 25px;
}

.sidenav .closebtn i:hover{
    color: #ffffff;
    background-color: orange;
    transition: 0.5s ease-in-out;
    border: 1px solid orange;
}

hr{
    height: 1px;
    width: 90%;
    margin: auto;
    background-color: var(--slider-color);
    border-width: 0px;
}

.sidenav .btn i{
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.sidenav .btn .arrow{
    margin-left: auto;
}

.sidenav .orderlist{
    display: flex;
    flex-direction: column;
    margin: 0px 10px 0px 35px;
}

.sidenav .orderlist .list-item{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid var(--slider-color);
    border-radius: 0 7px 7px 0;
    margin: 0 10px 0 0;
}

.sidenav .orderlist .theme-btn,
.sidenav .orderlist .language-btn{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-left: 1px solid var(--slider-color);
    border-radius: 0 7px 7px 0;
    margin: 0 10px 0 0;
}

.sidenav .orderlist .theme-btn p,
.sidenav .orderlist .language-btn p{
    margin-left: 5px;
}

.sidenav .orderlist .list-item:hover,
.sidenav .orderlist .theme-btn:hover,
.sidenav .orderlist .language-btn:hover{
    border-left: 1px solid var(--slider-color);
    border-bottom: 1px solid var(--slider-color);
    border-radius: 0 10px 0 10px;
}

.sidenav .btn p{
    margin-top: 2px;
    margin-left: 4px;
}

.sidenav .btn:hover,
.sidenav .orderlist a:hover,
.sidenav .orderlist .btn:hover{
    /* color: #1777f2; */
    /* color: #1ca1f1; */
    color: #47b5ff;
    background-color: var(--hover-color);    
}

.sidenav .btn i{
    height: 25px;
    width: 25px;
}

/* Nav Menu Ends */






/* ---------------------------------------------------------- */






.main_area{
    position: sticky;
    top: 0;   /* Actual line to change to floating design*/
    /* border-bottom: 1px solid var(--hover-color); */
}


.header{
    height: fit-content;
    width: 100%;
    max-width: 2000px;
    margin: auto;
    padding: 0.5% 0;
    /* color: #ffffff; */
    background-color: #47b5ff;
    /* background-color: #1777f2; */
    /* background-color: #1ca1f1; */

    /* background-color: #47b5ff; */
    /*backdrop-filter: blur(5px); /* Adds Glassy background to the header */

    /* background-color: var(--bg-color); */
    /* outline: 1px solid var(--slider-color); */
    /* outline-offset: -1px; */
    border-radius: 0 0 10px 10px;
    position: sticky;
    
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hamburger_btn{
    width: auto;
    padding: 5px;
    border-radius: 5px;
    margin-left: 15px;
}

.line{
    height: 4px;
    width: 27px;
    background-color: #ffffff;
    /* background-color: #47b5ff; */
    margin-bottom: 5px;
    border-radius: 5px;
}

.line-3{
    width: 27px;
    margin-bottom: 0px;
}

.logo_img{
    margin: 0;    
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 5px;
}

.header h3{
    font-size: 25px;
}









/* Responsive Design */

@media only screen and (min-width: 320px) and (max-width: 480px) and (orientation : portrait) {
    /* .main_area{
        top: 1%;
    } */

    .header{
        padding: 2% 0;
        margin: 0 auto;
        /* width: 95%; */
    }
  
    .logo{
        width: fit-content;
    }
}

@media (min-width: 481px) and (max-width: 767px) /* Mobile Landscape */ {
    /* .main_area{
        top: 1%;
    } */

    /* .header{
        width: 95%;
    } */
}

@media (min-width: 768px) and (max-width: 1024px) /*and (orientation : portrait)  /* Tablet Portrait */{
    /* .main_area{
        top: 1%;
    } */

    .header{
        /* width: 95%; */
        padding: 1% 0;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) /* and (orientation : Landscape) /* TAablet Landscape */ {
    /* .main_area{
        top: 1%;
    } */

    /* .header{
        width: 95%;
    } */
}