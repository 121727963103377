.brands-list{
    border: 1px solid black;
    overflow: hidden;
    width: 90%;
    max-width: 2000px;
    margin: auto auto 5% auto;
    border-radius: 10px;
}

.wrapper{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 15rem;
    
    justify-items: stretch;
    margin: 2% auto;
}

@keyframes scroll{
    to{
        translate: calc(-4 * 15rem);
    }
}

.wrapper{
    animation: scroll 10s linear infinite;
}