.dashed-lines-container {
  display: flex;
  justify-content: space-between; /* Optional, keeps spacing consistent */
  width: 100%; /* The parent div takes full width */
}

.dashed-line {
  flex-grow: 1; /* Each line adjusts dynamically to fill available space */
  height: 3px;
  margin: 0 2px; /* Adds spacing between lines */
  background-color: #ddd;
  border-radius: 2px;
  transition: background-color 0.3s;
}

.dashed-line.highlighted {
  background-color: #47b5ff;
}
